import { AppstoreOutlined, CaretRightOutlined, DownOutlined, UnorderedListOutlined, UpOutlined } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Topic } from "../../state";
import { IndexTable } from "../goals/ViewIndex";
import Indicator from "./Indicator";
import { FilterParams, GridHeader, SubAreacard } from "./ThematicAreaView";

const { Panel } = Collapse;
interface DataType{
    indicator_name: string;
    indicator_codes: string[];
}

const AreaTable: React.FC<{
    store: object | undefined;
    filterParams:  FilterParams | undefined;
    columns: ColumnsType<DataType>;
    area: Topic;
    opened?: boolean 
  }> = ({ 
    columns, 
    opened, 
    filterParams, 
    store, 
    area }) => {
    
    const [expandedRowKeys, setExpandedRowKeys] = useState<[]>([])
    const [loaded, setLoaded] = useState<boolean>(true)
    const [toggleCharts, setToggleCharts] = useState<boolean>(false)
    const [sortDesc, setSortDesc] = useState<boolean>(false)
    const [indicators, setIndicators] = useState([]);

    useEffect(() => {
        // setSearchTerm("");
        // setFilterParams({
        //   que:'',
        //   location: ['Earth'],
        //   partners: ['all'],
        //   statVars: []
        // })
        if(area)
        sortedIndicators();
      }, [area]);

      useEffect(()=>{
        if(filterParams)
        nestedSearch(filterParams)
      },[ filterParams ])

    const expandAll = () => {
        setToggleCharts(!toggleCharts)
        const allKeys = indicators?.length ? indicators.map(record => record.id) : [];
        setExpandedRowKeys(allKeys);
    };

    const collapseAll = () => {
        setToggleCharts(!toggleCharts)
        setExpandedRowKeys([]);
    };

    const onExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys([record.id]);
        } else {
            setExpandedRowKeys([]);
        }
    };

    const sortIndicators = (array) => {
        return array?.filter((item) => typeof item.indicator_name !== "undefined").sort((a, b) => a.indicator_name.localeCompare(b.indicator_name));
    };

    const nestedSearch = (params:FilterParams) => {
        // setFilterParams(params)
        if(area){
        const flat = flatIndicators([area], [], [], 0, area.id);
        const filtered = flat?.filter((item) => {
          const indicator = item?.indicator_name?.toLowerCase() ?? "";
          let queCheck = indicator.includes(params.que);
          let partnersCheck = params?.partners?.length ? (params.partners.includes('all') ? [] : params.partners.includes(item.agency)) : true;
          let locationCheck = params?.statVars?.length ? (params.location.includes('Earth') ? [] : params?.statVars.some(i=>item?.indicator_codes.includes(i))) : true
        //   let themeCheck = params?.thematics?.length&&params?.thematics?.some(i=>(i+'').includes('#')) ? 
        //   params.thematics.map(t=>((t+'').includes('#') ? (t+'').split('#')[1] : t)).some(
        //         (t)=>{
        //             return (t+'').split('+').includes(item.id)
        //             })
        //         :true
        //         console.log('themeCheck',themeCheck)
        return queCheck && locationCheck && partnersCheck //&& themeCheck
        });
        setIndicators(sortIndicators(filtered));
        setLoaded(true)}
    };


    const sortedIndicators = () => {
        let indicators = [];

        if (area?.children) {
            indicators = flatIndicators(area.children);
            indicators = Array.from(new Set(indicators.map((item) => item.indicator_name))).map((name) => indicators.find((item) => item.indicator_name === name));
            setIndicators(sortIndicators(indicators));
        }
    };

    const flatIndicators = (array, results = [], parents = [], parent = null, topicId = null) => {
        const uniqueIndicators = new Set(results.map((indicator) => indicator.indicator_name));
        array.forEach((item) => {
          let parentChain = [...parents, parent];
          if (item.indicator_name && (!topicId || parentChain.includes(topicId) || topicId === "all")) {
            if (!uniqueIndicators.has(item.indicator_name)) {
              results.push(topicId ? {...item, themeId:topicId}: item);
              uniqueIndicators.add(item.indicator_name);
            }
          }
          if (item.children) {
            flatIndicators(item.children, results, parentChain, item.id, topicId);
          }
        });
    
        return results;
      };

    return <AreaCollapse 
            collapsible="icon" 
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            defaultActiveKey={opened ? [area.id] : []}
            >
        <Panel key={area.id} header={<GridHeader>
            <Row gutter={8}>
                <div>
                    <Col>
                    <img src={`${area?.icon}`} />
                    </Col>
                    <Col span={19}>
                    <strong>{area?.label}</strong>
                    </Col>
                </div>
                <div className="filters">
                    <Col style={{ display: "inline-flex" }}>
                    <span style={{ fontSize: "12px" }}>
                        Found <strong>{indicators.length}</strong> indicator(s)
                    </span>
                    </Col>
                    <Col>
                    {!toggleCharts 
                    ? <AppstoreOutlined onClick={()=>expandAll()}/>
                    : <UnorderedListOutlined onClick={()=>collapseAll()}/>
                    }
                    </Col>
                    <Col>
                    {
                    sortDesc 
                    ? <UpOutlined  onClick={()=>setSortDesc(!sortDesc)}/>
                    : <DownOutlined  onClick={()=>setSortDesc(!sortDesc)}/>
                    }
                    </Col>
                </div>
            </Row>
        </GridHeader>}>


        <IndexTable 
            columns={columns} 
            expandable={
            {
                expandedRowRender: record => {
                return record.indicator_codes?.length && <SubAreacard>
                    <Indicator placeDcid="Earth" store={store} name={record.indicator_name} code={record.indicator_codes[0]} limit={1} />
                </SubAreacard>
                },
                rowExpandable: record => record.indicator_codes?.length,
                onExpand,
                expandedRowKeys
            }
            }
            rowKey={'id'}
            dataSource={indicators} 
        /> 
        </Panel>
    </AreaCollapse>
}

export default AreaTable

const AreaCollapse = styled(Collapse)`
.ant-collapse-header {
    padding: 3px!important;
    .ant-row {
        padding-left: 20px;
        margin: 0 0 1rem!important;
        transition: all .3s;
    }
    &[aria-expanded="false"] .ant-row {
        border-color: #fff;
        margin:0 0!important;
    }
}
.ant-collapse-expand-icon {
    padding-left: 8px;
    position: absolute;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}
`