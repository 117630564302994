import { ArrowLeftOutlined, CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Dropdown, Input, Layout, Menu, Row, Switch, TreeDataNode, TreeSelect } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import countries from "../../config/countries.json";
import { Place, useStoreState } from "../../state";
import { EARTH_PLACE_DCID, QUERY_PARAM_PLACE, QUERY_PARAM_VARIABLE, ROOT_TOPIC, WEB_API_ENDPOINT } from "../../utils/constants";
import DataCommonsClient from "../../utils/DataCommonsClient";
import CountriesContent, { GridSwitcher, Spinner } from "../countries/CountriesContent";
import { IndexTable } from "../goals/ViewIndex";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import { ContentCard, CountrySelect, ExploreBtn, Footnotes } from "../shared/components";
import AreaTable from "./AreaTable";
import ChartCarousel from "./ChartCarousel";
import Indicator from "./Indicator";

const { SHOW_PARENT } = TreeSelect;

const treeData = [
  {
    title: 'World', 
    value: 'Earth',
    key: 'Earth',
  }, 
  ...countries.countries.map(
    (c:Place) => {
      return {
      title:c.name, 
      value: c.dcid,
      key: c.dcid,
      children: []
    }}
  ).sort((a, b) => a.title.localeCompare(b.title))
]



export interface FilterParams{
  location: string[];
  partners: string[];
  thematics: number[]|string[];
  que: string;
  statVars?: string[];
}

const ThematicAreaView: React.FC = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { hash } = useLocation();
  const [indicator, setIndicator] = useState("");
  const [theme, setTheme] = useState("");
  const [subtheme, setSubTheme] = useState("");
  const [countryPage, setCountryPage] = useState(false);
  const [url, setURL] = useState(routeDataCommonsConstants.THEMATIC_AREAS);
  const [SDGMode, setSDGMode] = useState(false);
  const [noData, setNoData] = useState(false);
  const [showIndex, setShowIndex] = useState(true);
  const [visibleCount, setVisibleCount] = useState(10);
  const [indicators, setIndicators] = useState([]);
  const [indicatorTree, setIndicatorTree] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const store = useStoreState((s) => s);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [topics, setTopics] = useState([]);

  const [thematicAreas, setThematicAreas] = useState([]);
  const [themes, setThemes] = useState([]);
  const [partnerList, setPartnerList] = useState([] as TreeDataNode[]);
  const [loaded, setLoaded] = useState<boolean>(true)
  const [toggleCharts, setToggleCharts] = useState<boolean>(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [sortDesc, setSortDesc] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [filterPosition, setFilterPosition] = useState<string>('top')
  const [filterParams, setFilterParams] = useState<FilterParams>({
    que:'',
    location: ['Earth'],
    partners: ['all'],
    thematics: ['0'],
    statVars: []
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config/partners.json`);
        const json = await response.json();
        setPartnerList([{title: "All Partners", value: 'all', children: json.filter(a=>a.hidden != true).sort((a, b)=>a.label.localeCompare(b.label)).map((a)=>{return {title: a.label, value: a.value, key: a.value}})}]);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {

        let sorted = data.sort((a, b) => a.label.localeCompare(b.label))
        setTopics(sorted);
        // Hierarcical thematic areas
        setThematicAreas(sorted.map(a=>{
            return {
              title: a.label, 
              value: a.id +'', 
              children: a.children?.map(
                c=>({title: c.label, value: a.id+'#'+c.id, children:[]})
              ).filter(a=>{
                return !a.value?.includes('-topic')})
            }
          }
        ))
        setLoading(false)
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const placeDcid = searchParams.get(QUERY_PARAM_PLACE) || "africa";
  const variableDcid = searchParams.get(QUERY_PARAM_VARIABLE) || ROOT_TOPIC;

  const area = topics.find((e) => e.id == params.dcid);
  const columns: ColumnsType<DataType> = [
    {
      title: "Indicator",
      dataIndex: "indicator_name",
      key: "name",
      render: (text, record) => {
        if (typeof record.key != "undefined") {
          return <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${record.themeId}#${record.key}`}>{text}</Link>;
        } else {
          const parent = findSubTheme(topics, record.id);
          return <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${record.themeId}#${parent.id}+${record.id}`}>{text}</Link>
        }
      },
    },
    {
      title: "Agency",
      dataIndex: "agency",
    },
  ];

  

  const findItem = (array, code) => {
    let result;
    array.some((child) => {
      return (
      (Array.isArray(code) ? code.includes(child.id+'') : child.id == code) && 
        (result = child)
      ) || 
      (result = findItem(child.children || [], code))
    });
    return result;
  };

  const findSubTheme = (nodes, childKey) => {
    for (const node of nodes) {
      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          if (child.children && child.children.length > 0) {
            for (const grandchild of child.children) {
              if (grandchild.id === childKey) {
                return child;
              }
            }
          }
        }
      }
    }
    return null;
  };

  const flatIndicators = (array, results = [], parents = [], parent = null, topicId = null) => {
    const uniqueIndicators = new Set(results.map((indicator) => indicator.indicator_name));

    array.forEach((item) => {
      let parentChain = [...parents, parent];
      if (item.indicator_name && (!topicId || parentChain.includes(topicId) || topicId === "all")) {
        if (!uniqueIndicators.has(item.indicator_name)) {
          results.push(item);
          uniqueIndicators.add(item.indicator_name);
        }
      }
      if (item.children) {
        flatIndicators(item.children, results, parentChain, item.id, topicId);
      }
    });

    return results;
  };

  const changeIndexView = (checked: boolean) => {
    setShowIndex(checked);
  };

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const getCountryLabel = (id) => {
    if (store.countries.byDcid[id]) {
      return store.countries.byDcid[id].name;
    }
    if (store.regions.byDcid[id]) {
      return store.regions.byDcid[id].name;
    }
  };

  useEffect(() => {
    if (topics.length > 0) {
      const path = window.location.pathname.split("/");
      let slug:string
      let params: FilterParams = {...filterParams}
      try {
        slug = base64Decode(path.slice(-1)[0])
        params = {
          location: slug.split('&')[0] ? slug.split('&')[0].split(',') : ['Earth'],
          thematics: slug.split('&')[1] ? slug.split('&')[1].split(',') : ['0'],
          partners: slug.split('&')[2] ? slug.split('&')[2].split(','): ['all']
        }
      } catch {
        slug = path.slice(-1)[0]
        params.thematics = slug.split(',')
      }

      if(params.thematics?.length){
        
        if(params.thematics.includes('0') && params.thematics.length > 1) 
          params.thematics = params.thematics.filter(t=>t!=0)
        
        setThemes(
          params.thematics?.map(code=>{
            let path = (code+'').split('#')
            let topic = topics.find(t=>t.id==path[0])
            let subTopics = path[1] && topic.children.filter(t=>t.id==path[1])
            return path[1] ? {...topic, children: subTopics, label: `${topic.label} / ${subTopics[0].label}`} : topics.find(t=>t.id==path[0])
          })
        )
        setTheme(
          params.thematics.map(code=>findItem(topics, code))
        );
      }
      setFilterParams({...filterParams, ...params})
      // if (window.location.pathname.includes("countries")) {
      //   setCountryPage(true);
      //   setURL(routeDataCommonsConstants.COUNTRY);
      // }

      // const sp = window.location.search;
      // if (sp && new URLSearchParams(sp).has("v")) {
      //   setSDGMode(true);
      //   history.push(routeDataCommonsConstants.COUNTRY + window.location.search);
      // } else {
        setSDGMode(false);
      // }
      document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
    }
  }, [topics, window.location.pathname, window.location.search]);


  useEffect(() => {
    if (hash) {
      const path = hash.replace(/#/g, "").split("+");
      if (path[1]) {
        setIndicator(findItem(topics, path[1]));
        setSubTheme(findItem(topics, path[0]));
      } else if (path[0]) {
        setIndicator(null);
        setSubTheme(findItem(topics, path[0]));
      }
    } else {
      setIndicator(null);
      setSubTheme(null);
    }
    document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
  }, [window.location.hash, topics]);

  // useEffect(() => {
  //   console.log('tyan')
  //   setSearchTerm("");
  //   setFilterParams({
  //     que:'',
  //     location: ['Earth'],
  //     partners: ['all'],
  //     statVars: []
  //   })
  //   sortedIndicators();
  //   sortedIndicatorTree();
  // }, [subtheme, theme]);

  useEffect(() => {
    // Reset the collapse state when params.dcid changes
    setActiveKey([]);
  }, [params.dcid]);

  const containerRef = useRef(null);

  useEffect(() => {
    const hideExtraNoDataDivs = () => {
      const divs = document.querySelectorAll("span.no-data-class");
      divs.forEach((div, index) => {
        if (index > 0) {
          div.style.display = "none";
        } else {
          div.style.display = "block";
        }
      });
    };

    // Initial call to hide extra divs
    hideExtraNoDataDivs();

    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          hideExtraNoDataDivs();
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  /**
   * Update selected place URL parameter
   */
  const setPlaceDcid = useCallback(
    (placeDcid: string) => {
      const hash = window.location.hash;
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(QUERY_PARAM_PLACE, placeDcid);

      if (hash) {
        history.push(location.pathname + "?" + searchParams.toString() + hash);
      } else {
        history.push(location.pathname + "?" + searchParams.toString());
      }
    },
    [location]
  );

  const getIndicators = () => {
    return (
      <>
        <IndicatorHeader>
          <Row>
            {indicator && <Col flex="0 1">
              <BackButton size="small" onClick={history.goBack}><ArrowLeftOutlined /></BackButton>
            </Col>}
            <Col flex="1 1">
              <span>{indicator.indicator_name}</span>
            </Col>
          </Row>
        </IndicatorHeader>
        <SubAreacard style={{ backgroundColor: "transparent", margin: 0 }} ref={containerRef}>
          <ChartCarousel topics={topics || []} placeDcid="Earth" store={store} name={indicator.indicator_name} codes={indicator.indicator_codes} limit={100}/>
        </SubAreacard>
      </>
    );
  };

  const getCountryPageIndicators = () => {
    return (
      <>
        <CountrySelectCard>
          <h3>{getCountryLabel(placeDcid)}</h3>
          <CountrySelect setSelectedPlaceDcid={setPlaceDcid} />
        </CountrySelectCard>

        <h3 className="subheader">
          {`${area?.label}`} · {`${subtheme?.label}`}
        </h3>
        <SubAreacard>
          {store.countries.dcids.length &&
            store.regions.dcids.length &&
            indicator.indicator_codes.map((i, k) => {
              return (
                <Indicator key={k} setter={noData} hideAlternates={true} placeDcid={placeDcid} store={store} name={indicator.indicator_name} code={i} limit={100} />
              );
            })}
        </SubAreacard>
      </>
    );
  };

  async function getFulFill(variables:string[], entities:string[]){
    const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT });
    
    if(!entities.length) return false;
    
    try {
      // Get all SDGs articles
      const response:ArticleResponse = await client.fulfill({
        variables,
        dc: "undata",
        entities: entities || filterParams.location
      });
      return response;
    } catch (error) {
      console.error('Error fetching thematics:', error);
    }
    return false;
  }


  const FilterOptions = ({position}) => {
    return <FilterWrapper>
          {position == 'left' &&
          <div className="sidebar-title">
            Filter content 
            <FilterBtn/>
          </div>
          }
          <Row className={"position-"+position} gutter={[12, 12]}>
            {position == 'top' &&
            <Col span={1}>
              <FilterBtn/>
            </Col>
            }
            <Col span={6}>
              <TreeSelect
                suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                showSearch
                style={{ width: "100%", display: "block", borderRadius: "50%" }}
                value={filterParams.location}
                treeCheckable
                showArrow
                showCheckedStrategy={SHOW_PARENT}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={`Location | World`}
                allowClear
                treeDefaultExpandAll
                onChange={onLocationChange}
                treeData={treeData}
              />
            </Col>
            <Col span={6}>
              <TreeSelect
                suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                showSearch
                style={{ width: "100%", display: "block", borderRadius: "50%" }}
                // value={theme?.id}
                value={filterParams.thematics}
                treeCheckable
                showArrow
                showCheckedStrategy={SHOW_PARENT}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={`Thematic Areas | All`}
                allowClear
                treeDefaultExpandAll
                onChange={onThematicChange}
                treeData={thematicAreas}
              />
            </Col>
            <Col span={6}>
              <TreeSelect
                suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                showSearch
                style={{ width: "100%", display: "block", borderRadius: "50%" }}
                value={filterParams.partners}
                treeCheckable
                showArrow
                showCheckedStrategy={SHOW_PARENT}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={`Partners | All`}
                allowClear
                treeDefaultExpandAll
                onChange={onPartnersChange}
                treeData={partnerList}
              />
            </Col>
          </Row>
        </FilterWrapper>
  }
  const FilterBtn = () => (<Dropdown overlay={menu} trigger={['click']}>
      <RadialButton onClick={(e) => e.preventDefault()}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
          <path d="M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z"/>
        </svg>
      </RadialButton>
    </Dropdown>
  )
  
  const menu = () => (
      <Menu
        onClick={(info) => {
          setFilterPosition(info.key)
        }}
      >
        <Menu.ItemGroup title="Filter layout options">
          <Menu.Item 
            key="left" 
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_34089_45757)">
                  <path d="M17.5002 2.5H2.50016C1.5835 2.5 0.833496 3.25 0.833496 4.16667V15.8333C0.833496 16.75 1.5835 17.5 2.50016 17.5H17.5002C18.4168 17.5 19.1668 16.75 19.1668 15.8333V4.16667C19.1668 3.25 18.4168 2.5 17.5002 2.5ZM17.5002 15.8333H2.50016V4.16667H17.5002V15.8333ZM3.3335 5H5.8335V15H3.3335V5Z" fill="#7C7C7C"/>
                </g>
                <defs>
                  <clipPath id="clip0_34089_45757">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>}>
            Sidebar filters
          </Menu.Item>
          <Menu.Item 
            key="top" 
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_34090_61616)">
                  <path d="M17.5002 2.5H2.50016C1.5835 2.5 0.833496 3.25 0.833496 4.16667V15.8333C0.833496 16.75 1.5835 17.5 2.50016 17.5H17.5002C18.4168 17.5 19.1668 16.75 19.1668 15.8333V4.16667C19.1668 3.25 18.4168 2.5 17.5002 2.5ZM17.5002 15.8333H2.50016V4.16667H17.5002V15.8333ZM4.16683 5H15.8335V7.5H4.16683V5Z" fill="#7C7C7C"/>
                </g>
                <defs>
                  <clipPath id="clip0_34090_61616">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>}>
            Top bar filters
          </Menu.Item>
          <Menu.Item key="none" icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_34090_61620)">
                  <path d="M10.0002 5.41667C13.1585 5.41667 15.9752 7.19167 17.3502 10C15.9752 12.8083 13.1668 14.5833 10.0002 14.5833C6.8335 14.5833 4.02516 12.8083 2.65016 10C4.02516 7.19167 6.84183 5.41667 10.0002 5.41667ZM10.0002 3.75C5.8335 3.75 2.27516 6.34167 0.833496 10C2.27516 13.6583 5.8335 16.25 10.0002 16.25C14.1668 16.25 17.7252 13.6583 19.1668 10C17.7252 6.34167 14.1668 3.75 10.0002 3.75ZM10.0002 7.91667C11.1502 7.91667 12.0835 8.85 12.0835 10C12.0835 11.15 11.1502 12.0833 10.0002 12.0833C8.85016 12.0833 7.91683 11.15 7.91683 10C7.91683 8.85 8.85016 7.91667 10.0002 7.91667ZM10.0002 6.25C7.9335 6.25 6.25016 7.93333 6.25016 10C6.25016 12.0667 7.9335 13.75 10.0002 13.75C12.0668 13.75 13.7502 12.0667 13.7502 10C13.7502 7.93333 12.0668 6.25 10.0002 6.25Z" fill="#7C7C7C"/>
                </g>
                <defs>
                  <clipPath id="clip0_34090_61620">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>}>
            Hide filters
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  const getTopics = () => {

    return (
      <>
        <SearchWrapper>
          <Row gutter={[12, 12]}>
            <Col span={filterPosition === 'none' ? 23 : 24}>
              <Input prefix={<SearchOutlined />} style={{ width: "100%" }} value={filterParams.que} onChange={queSearch} placeholder="Search" />
            </Col>
            {filterPosition === 'none' &&
            <Col span={1}>
              <FilterBtn/>
            </Col>
            }
          </Row>
          {filterPosition == 'top' && <FilterOptions position={filterPosition}/>}
        </SearchWrapper>

        {area?.children.length &&
          area?.children.map((v, k) => {
            return (
              <React.Fragment key={k}>
                {!showIndex && v.children.length > 0 && (
                  <>
                    <Link to={`#${v.id}`}>
                      <SubAreaTitle style={{ borderColor: `${theme.color};`, color: `${theme.color}` }}>{v.label}</SubAreaTitle>
                    </Link>
                    <SubAreacard>
                      {store.countries.dcids.length && <Indicator placeDcid="Earth" store={store} name={v.label} code={v.key} isTopic={true} limit={1} />}
                    </SubAreacard>
                  </>
                )}

                {v.id == "646330495-topic" && (
                  <SubAreacard>
                    <h3 className="block-header">{v.label}</h3>
                    <p style={{ padding: "1rem" }}>No data found.</p>
                  </SubAreacard>
                )}
              </React.Fragment>
            );
          })}

        {showIndex && (
          <GridTableWrapper style={{ backgroundColor: "#fff", borderRadius: 8 }}>
            {loading && <Spinner/>}
            {!loading && themes?.length > 0 && (themes.filter((i)=>i?.id!=0).length > 0 ? themes : topics).filter((i)=>i?.id!=0).map((theme, key)=>{
              let defOpened = themes.length == 1 && filterParams.thematics[0] != '0'
              return <AreaTable 
                columns={columns} 
                filterParams={filterParams} 
                key={`${key}-${defOpened}`} 
                area={theme} 
                store={store}
                opened={defOpened}
              />
            })}
          </GridTableWrapper>
        )}
      </>
    );
  };

  const getAllTopics = () => {
    return (
      <>
        {topics
          .filter((v) => v.type != "placeholder")
          .map((v, k) => {
            return (
              <Link key={k} to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${v.id}`}>
                <AreaCard>
                  <Row className="area-row">
                    <Col flex="0 1">
                      <img src={`${v?.icon}`} alt={v?.label} />
                    </Col>
                    <Col flex="1 1">
                      <h3>{v?.label}</h3>
                      <span>{v?.description}</span>
                    </Col>
                  </Row>
                </AreaCard>
              </Link>
            );
          })}
      </>
    );
  };


  

  const base64Encode = (str:string) => {
    return btoa(str);
  }
  const base64Decode = (encodedStr:string) => {
    return atob(encodedStr);
  }
  const onThematicChange = (e) => {
    nestedSearch({...filterParams, thematics: (filterParams.thematics?.length&&filterParams.thematics.includes(0)) ? e.filter(i=>i>0):e})
  }

  const onLocationChange = (newValue: []) => {
    let statVars:string[] = []
    if(themes.length){
      setLoaded(false)

      // update location before load fulfill
      setFilterParams({...filterParams, location: newValue})
      getFulFill(themes.map(i=>i.key), newValue).then((res) => {
        // setCountriesExistence(res)
        if(res.config?.categories?.[0]?.statVarSpec)
        statVars = Object.keys(res.config.categories[0].statVarSpec)
        nestedSearch({...filterParams, location: newValue, statVars})
      });
    }
  };

  const onPartnersChange = (newValue: []) => {
    nestedSearch({...filterParams, partners: newValue});
  };

  const queSearch = (value: string) => {
    nestedSearch({...filterParams, que: value?.target?.value})
  }


  const nestedSearch = (params:FilterParams) => {

    setFilterParams(params)
    // const flat = flatIndicators(topics, [], [], 0, theme.id);
    // const filtered = flat?.filter((item) => {
    //   const indicator = item?.indicator_name?.toLowerCase() ?? "";
    //   let queCheck = indicator.includes(params.que);
    //   let partnersCheck = params?.partners?.length ? (params.partners.includes('all') ? [] : params.partners.includes(item.agency)) : true;
    //   let locationCheck = params?.statVars?.length ? (params.location.includes('Earth') ? [] : params?.statVars.some(i=>item?.indicator_codes.includes(i))) : true
    //   return queCheck && locationCheck && partnersCheck
    // });
    // setIndicators(sortIndicators(filtered));
    // setLoaded(true)
    let str = [
      `${params.location.join(',')}`,
      `${params.thematics.join(',')}`, 
      `${params.partners.join(',')}`
    ].join('&')

    let str64 = base64Encode(str)

    history.push(routeDataCommonsConstants.THEMATIC_AREAS + '/'+str64);
  };


  const getSubTopics = () => {
    return (
      <>
        <Row>
          <Col lg={12}>
            {showIndex && (
              <h3 className="subheader">
                {`${area?.label}`} / {`${subtheme?.label}`}
              </h3>
            )}
          </Col>
          <Col lg={12} style={{ textAlign: "right" }}>
            <GridSwitcher style={{ paddingTop: "17px", margin: "0 3.5rem" }}>
              <Switch checked={showIndex} onChange={changeIndexView} size="small" /> Data catalogue
            </GridSwitcher>
          </Col>
        </Row>
        {!showIndex && (
          <SubAreaTitle style={{ backgroundColor: `${theme.color}20`, borderColor: `${theme.color}`, marginBottom: 0, color: `${theme.color}` }}>
            {subtheme?.label}
          </SubAreaTitle>
        )}
        <SubAreacard style={{ backgroundColor: "transparent", margin: 0 }}>
          {showIndex && (
            <GridTableWrapper>
              <Input value={searchTerm} onChange={search} placeholder="Search" />
              <div className="results-subheader">
                Found <strong>{indicators.length}</strong> indicator(s)
              </div>
              <IndexTable columns={columns} dataSource={indicators} />
            </GridTableWrapper>
          )}

          {!showIndex && (
            <div>
              {indicators.slice(0, visibleCount).map((v, index) => (
                <React.Fragment key={index}>
                  <Link to={`${hash + "+" + v.id}`}>
                    <SubAreaTitle style={{ margin: "1rem", borderColor: `${theme.color};`, color: `${theme.color}` }} key={index}>
                      {v.indicator_name}
                    </SubAreaTitle>
                  </Link>
                  <Indicator placeDcid="Earth" name={subtheme.label} store={store} code={v.indicator_codes[0]} isTopic={true} limit={1} />
                </React.Fragment>
              ))}
              {visibleCount < indicators.length && (
                <ExploreBtn>
                  <button onClick={loadMore}>Explore more</button>
                </ExploreBtn>
              )}
            </div>
          )}

          {/*!showIndex && <Indicator placeDcid="Earth" name={subtheme.label} store={store} code={subtheme.key} isTopic={true} limit={100} />*/}
        </SubAreacard>
      </>
    );
  };

  const getIndicatorsBySubTopic = () => {
    return (
      <>
        <CountrySelectCard>
          <h3>{getCountryLabel(placeDcid)}</h3>
          <CountrySelect setSelectedPlaceDcid={setPlaceDcid} />
        </CountrySelectCard>

        <h3 className="subheader">
          {`${area?.label}`} · {`${subtheme?.label}`}
        </h3>
        <SubAreacard>
          {store.countries.dcids.length && store.regions.dcids.length && (
            <Indicator placeDcid={placeDcid} hideAlternates={true} store={store} name={subtheme.label} code={subtheme.key} isTopic={true} limit={100} />
          )}
        </SubAreacard>
      </>
    );
  };

  const getIndicatorsByTopic = () => {
    return (
      <>
        <CountrySelectCard>
          <h3>{getCountryLabel(placeDcid)}</h3>
          <CountrySelect setSelectedPlaceDcid={setPlaceDcid} />
        </CountrySelectCard>

        <h3 className="subheader">{`${area?.label}`}</h3>
        <SubAreacard style={{ margin: "0 20px" }}>
          {store.countries.dcids.length && <Indicator store={store} placeDcid={placeDcid} name={area.label} code={area.key} isTopic={true} limit={1000} />}
        </SubAreacard>
      </>
    );
  };

  return (
    <>
      <AppLayoutContent>
        <div id="top"></div>
        {topics.length > 0 ? (
          <>
            {!countryPage ? (
              <Layout className="container">
                <AppBreadcrumb
                  items={[
                    { name: countryPage ? "Countries / Areas" : "Thematic Areas", to: url },
                    { name: subtheme && area?.label, to: `${url}/${theme.id}` },
                    { name: indicator && subtheme?.label, to: `${url}/${theme.id}` },
                  ].filter((i) => i.name)}
                >
                  {indicator ? indicator?.indicator_name : subtheme ? subtheme.label : area?.label}
                </AppBreadcrumb>
              </Layout>
            ) : (
              <Layout
                className="container"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <AppBreadcrumb>{countryPage ? "Countries / Areas" : "Thematic Areas"}</AppBreadcrumb>
              </Layout>
            )}
            <Layout style={{ height: "100%", flexGrow: 1, flexDirection: "row" }}>
              {filterPosition == 'left' && <FilterOptions position={filterPosition}/>
              // <AppSidebar
              //   dcid={params.dcid}
              //   placeDcid={placeDcid}
              //   setVariableDcid={setVariableDcid}
              //   showThematicAreas={true}
              //   thematicArea={area}
              //   onlySDGlink={countryPage ? false : true}
              //   countryPage={countryPage}
              // />
              }
              <Layout style={{ overflow: "auto", backgroundColor: "#f2f2f2" }}>
                <AreaBody className="container">
                  {SDGMode ? (
                    <CountriesContent hidePlaceSearch={false} variableDcids={[variableDcid]} placeDcids={[EARTH_PLACE_DCID]} setPlaceDcid={() => {}} />
                  ) : (
                    <>
                      {countryPage ? (
                        <>
                          {indicator && getCountryPageIndicators()}
                          {subtheme && !indicator && getIndicatorsBySubTopic()}
                          {theme && !subtheme && !indicator && getIndicatorsByTopic()}
                        </>
                      ) : (
                        <>
                          {params.dcid == 0 && getAllTopics()}
                          {params.dcid != 0 && indicator && getIndicators()}
                          {params.dcid != 0 && subtheme && !indicator && getSubTopics()}
                          {params.dcid != 0 && theme && !subtheme && !indicator && getTopics()}
                        </>
                      )}
                    </>
                  )}
                  <div style={{ padding: "1rem" }}>
                    <Footnotes />
                  </div>
                </AreaBody>
              </Layout>
            </Layout>
          </>
        ) : (loading ? <ContentCard><Spinner/></ContentCard> : null)}
      </AppLayoutContent>
    </>
  );
};

export default ThematicAreaView;




const AreaTop = styled.div`
  background-image: url("./images/datacommons/search-background.png");
  background-size: 100% auto;
  padding: 0 2rem; 1rem
  border-bottom: solid 2px #d3d3d3;
  h3 {
    color: inherit;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    padding-top: 0.5rem;
    color: #616161;
    width: 40%;
  }
  @media only screen and (max-width: 800px) {
    p {
      width: 100%;
    }
  }
`;

const AreaBody = styled.div`
  .subheader {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #343434;
    margin: 2rem 1rem 1rem 1rem;
    padding-bottom: 0.5rem;
    display: block;
    @media (min-width: 992px) {
      margin: 19px 1.5rem 17px 1.5rem;
      padding-bottom: 0;
    }
  }
  > div {
    margin-left:0!important;
    margin-right:0!important;
  }
`;

const AreaCard = styled.div`
  .area-row {
    padding: 0 16px;
    display: inline-block;
    img {
      margin: 1rem 1rem 1rem 0;
    }
  }
  margin: 1rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  img {
    margin: 1rem;
    border-radius: 8px;
    padding: 0.5rem;
    height: 120px;
    background-color: #f1f1f1;
  }
  span {
    display: block;
    width: 80%;
    padding-bottom: 2rem;
    color: #4d4d4d;
  }
  h3 {
    font-size: 36px;
    padding: 1rem 0 0.3rem 0;
  }
  @media (min-width: 992px) {
    img {
      margin: 2rem;
    }
    h3 {
      font-size: 36px;
      padding: 2rem 0 0.3rem 0;
    }
    > .area-row {
      display: flex;
    }
    margin: 1.5rem;
  }
`;

const CountrySelectCard = styled.div`
  margin: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  h3 {
    font-size: 2rem;
    padding-bottom: 6px;
    padding-top: 6px;
    justify-content: space-between;
    font-weight: normal;
  }
  @media (min-width: 992px) {
    padding: 1.5rem;
    margin: 1.5rem;
  }
`;

const SearchBarContainer = styled.div`
  .search {
    height: 100%;
    input {
      border-radius: 120px !important;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 30px;
    }
  }

  .anticon.anticon-search {
    margin-right: 5px;
    background-image: url(./images/datacommons/search-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 100%;
    width: 45px;
    right: 0;
    top: 0;

    svg {
      display: none;
    }
  }
`;

const SubAreaTitle = styled.div`
  background-color: #fff;
  margin: 1rem;
  padding: 0.8rem;
  border-radius: 8px;
  border: 2px solid;
  background: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width: 992px) {
    margin: 1.5rem;
    padding: 0.8rem;
  }
`;

export const SubAreacard = styled.div`
  background-color: #fff;
  // margin: 1rem;
  // padding: 0.5rem;
  border-radius: 8px;
  .block-header {
    padding: 16px 16px 0;
  }
  @media (min-width: 992px) {
    // margin: 1.5rem;
    padding: 0.5rem;
  }
  .no-data-class {
    padding: 1rem;
  }

  datacommons-bar::part(container),
  datacommons-line::part(container),
  datacommons-map::part(container) {
    margin: 0px !important;
    width: 100%;
    display: inline-block;
  }
  datacommons-highlight::part(container) {
    margin: 0 !important;
    width: calc(100% + 0px);
    display: inline-block;
  }
`;

const IndicatorHeader = styled.div`
  margin: 1rem;
  background-color: #fff;
  border-radius: 8px;
  span {
    font-weight: bold;
    display: block;
    padding: 1rem 8px;
    max-width: calc(100% - 145px);
  }
  img {
    padding: 1rem;
  }
  @media (min-width: 992px) {
    margin: 1.5rem;
    margin-bottom: 0;
  }
`;

export const GridTableWrapper = styled.div`
  margin: 0 1rem;
  > input.ant-input {
    border-radius: 20px !important;
    padding: 4px 16px;
  }
  .results-subheader {
    padding: 8px 0 16px 16px;
    font-size: 12px;
  }
`;

export const GridHeader = styled.div`
  > .ant-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: solid 1px;
    margin: 1rem 0!important;
    > div {
      display: flex;
      align-items: center;
      flex-grow: 1;
      &.filters {
        flex: none;
      }
    }
  }
`

const SubthemeIndicator = styled(Indicator)`
  padding: 24px;
  margin: 100px;
`;

const SwitcherWrap = styled.div`
  text-align: right;
  margin-bottom: 2rem;
  @media (max-width: 992px) {
    padding: 0 16px;
    margin-bottom: 1rem;
  }
`;
const StyledCollapse = styled(Collapse)`
  .ant-collapse-arrow {
    transform: translateY(-25%);
  }
`;

const FilterWrapper = styled.div`
  padding-top: 24px;
 > .ant-row.position-left {
  padding: 15px 20px;
    > .ant-col {
      width:100%;
      flex: auto;
      max-width: 100%; 
    }
  }
  .sidebar-title {
    padding: 10px 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--surface-surface-stroke, #CCC);
    .ant-btn {
      border: none;
      svg {
        width: 18px !important;
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) 
  .ant-select-selector, 
  .ant-input, 
  .react-dropdown-select {
    border-radius: 20px!important;
    border: solid #DADCE0 1px!important;
    
  }
  .ant-select-selection-item {
    background: transparent;
    border-radius: 20px;
    padding: 0 8px;
  }
`

const SearchWrapper = styled.div`
  padding: 1rem;
  background-color: #fff;
  margin: 1rem;
  .ant-input-affix-wrapper {
    border-radius: 30px;
    border: solid #DADCE0 1px;

    .ant-input-prefix {
      margin-right: 6px;
    }
  }
  ::placeholder {
    color: #202020;
    opacity: 1;
  }
  ::-ms-input-placeholder {
    color: #202020;
  }
`
const RadialButton = styled(Button)`
  padding: 4px;
  width: 32px;
  border-radius: 100%;
  > svg {
    margin-right: 0!important;
  }
`
const BackButton = styled(Button)`
  height: 100%;
  border: none;
  &.ant-btn svg {
    margin-right: 0!important;
    width: 16px !important;
  }
`